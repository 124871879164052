<template>
  <div class="login-page">
    <div>
      <div class="login-header">
        <div class="brand">
          <img class="logo-img" :src="logoImg" alt="">
        </div>
        <div class="login-welcome">
          欢迎登录
        </div>
      </div>
      <div class="login-body" :style="{'background-image': bgPicture ? 'url('+bgPicture+')' : null}">
        <div class="login-container">
          <div class="login-form">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="账号密码登录" name="account">
                <el-form ref="loginForm" :model="loginForm" :rules="loginRules" label-position="left" label-width="0px">
                  <el-form-item prop="username">
                    <el-input v-model="loginForm.username" type="text" name="username" auto-complete="off" placeholder="请输入手机号" maxlength="11" />
                  </el-form-item>
                  <el-form-item prop="" class="password">
                    <el-input v-model="loginForm.password" :type="passType" :placeholder="pwdPlaceholder" name="password" auto-complete="off" />
                    <i :class="['iconfont', {'icon-xianshimima': passType === 'text', 'icon-password-hide': passType === 'password'}]" @click="togglePass" />
                  </el-form-item>
                  <el-form-item class="login-opr clearfix">
                    <el-checkbox v-model="checked" checked class="remember f_l">
                      记住密码
                    </el-checkbox>
                    <!-- <div target="_blank" @click="toggleStatus('forgotPass')" class="forgot-pwd">忘记密码</div> -->
                  </el-form-item>
                </el-form>
              </el-tab-pane>
              <el-tab-pane label="手机号登录" name="phone">
                <el-form ref="iphoneForm" :model="iphoneForm" :rules="iphoneRules" label-position="left" label-width="0px">
                  <el-form-item prop="username">
                    <el-input v-model="iphoneForm.username" type="text" name="username" auto-complete="off" placeholder="请输入手机号" maxlength="11" />
                  </el-form-item>
                  <el-form-item prop="" class="password">
                    <el-row :gutter="20">
                      <el-col :span="14">
                        <el-input v-model="iphoneForm.password" placeholder="请输入验证码">
                          <i slot="prefix" class="el-icon-tickets" />
                        </el-input>
                      </el-col>
                      <el-col :span="8">
                        <el-button class="btnText" :disabled="disabled" @click="sendCode">
                          {{ btnText }}
                        </el-button>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
            </el-tabs>
            <div class="nc-form-item">
              <div id="ic" />
            </div>
            <el-button type="primary" class="submit-btn" :loading="loading" :disabled="submitDisabled" @click.native.prevent="login">
              登录
            </el-button>
            <div class="mode">
              <span class="mode-text">
                其他登录方式
              </span>
            </div>
            <div>
              <div class="mode-icon-box" @click="goToShopex">
                <el-tooltip effect="dark" content="商派" placement="top">
                  <img src="../../../assets/login_click.png">
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { login, sendCode, identification } from '@/api/auth';
import { mapGetters, mapActions, mapState } from 'vuex';
import PwdRule from '@/mixins/pwd-rule';
export default {
  name: 'Login',
  mixins: [PwdRule],
  data () {
    let validateMobile = (rule, value, callback) => {
      let rule1 = /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/;
      if (!value) {
        return callback(new Error('请输入11位数字手机号'));
      } else if (!rule1.test(value)) {
        return callback(new Error('手机号码格式不对'));
      }
      callback();
    };

    return {
      loginForm: {
        username: '',
        password: ''
      },
      iphoneForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [
          { required: true, validator: validateMobile, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ]
      },
      iphoneRules: {
        username: [
          { required: true, validator: validateMobile, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请填写验证码', trigger: 'blur' }
        ]
      },
      checked: true,
      submitDisabled: false,
      loading: false,
      passType: 'password',
      ncParams: {
        sessionId: null,
        sig: null,
        token: null,
        scene: 'ic_login',
        type: 'PWD',
      },
      activeName: 'account',
      disabled: false,
      btnText: '获取验证码',
      time: 60,
    };
  },
  // 不！能！获取组件实例 `this`
  async beforeRouteEnter (to, from, next) {
    if (to.params.pathMatch) {
      const params = {
        id: to.params.pathMatch
      };
      const res = await identification(params);
      if (res.code) {
        next({
          path: "/notfound"
        });
      } else {
        next(vm => {
          // 存储 租户信息
          vm.setTenantInfo(res);
        });
        next({
          path: "/login"
        });
      }
      return;
    }
    next();
  },
  computed: {
    ...mapGetters([
      'menus'
    ]),
    ...mapState({
      bgPicture: state => state.tenant.bgPicture,
      entLogo: state => state.tenant.entLogo,
    }),
    logoImg () {
      let url = '';
      url = this.entLogo ? this.entLogo : require('../../../assets/logo-img.png');
      return url;
    },
  },
  beforeCreate () {
    if (!process.env.VUE_APP_PROCESS) {
      let url = process.env.VUE_APP_IFRAME_URL;
      const loginUrl = `${url}/authorize?client_id=43d93dab7ebef303&response_type=code&view=ACCOUNT`;
      window.location.replace(loginUrl);
    }

  },
  mounted () {
    this.init();
    this.setUserInfo({});
  },
  methods: {
    ...mapActions({
      getMenuList: 'menu/getMenuList',
      setUserInfo: 'user/setUserInfo',
      setTenantInfo: 'tenant/setTenantInfo',
    }),
    init () {
      // eslint-disable-next-line no-undef
      AWSC.use('ic', (state, module) => {
        window.ic = module.init({
          appkey: 'FFFF0N1N0000000067D9',
          scene: this.ncParams.scene,
          renderTo: 'ic',
          success: (data) => {
            this.ncParams.sessionId = data.sessionId;
            this.ncParams.sig = data.sig;
            this.ncParams.token = data.token;
          },
          fail: (failCode) => {
            console.log('fail: ', failCode);
          },
          error: (errorCode) => {
            console.log('error: ', errorCode);
          }
        });
      });
    },
    togglePass () {
      this.passType = this.passType === 'text' ? 'password' : 'text';
    },
    async login () {
      const active = this.activeName;
      if (active === 'account') {
        this.accountLogin();
      } else {
        this.iphoneLogin();
      }
    },
    async accountLogin () {
      try {
        await this.$refs.loginForm.validate();
        const isClickCheck = !this.ncParams.sessionId || !this.ncParams.token || this.ncParams.tsig;
        if (isClickCheck) {
          return this.$message.error('请点击智能校验');
        }
        this.loading = true;
        this.submitDisabled = true;
        this.ncParams.type = 'PWD';
        const loginForm = {
          ...this.loginForm,
          ...this.ncParams
        };
        const loginRes = await login(loginForm, { customError: true });
        this.setUserInfo(loginRes);
        await this.getMenuList();

        this.$message.success("登录成功");
        if (loginRes.superAdmin) {
          setTimeout(() => this.$router.push("/"));
        } else {
          setTimeout(() => this.$router.push("/auth/operator"));
        }
        // setTimeout(() => this.$router.push("/auth/operator"));

      } catch (e) {
        console.error(e);
        this.submitDisabled = false;
        this.loading = false;
        if (e && e.msg) {
          this.$message({
            message: e.msg,
            type: 'error',
            duration: 2 * 1000,
            onClose: () => window.ic.reset()
          });
        }
      }
    },
    async iphoneLogin () {
      try {
        await this.$refs.iphoneForm.validate();
        const isClickCheck = !this.ncParams.sessionId || !this.ncParams.token || this.ncParams.tsig;
        if (isClickCheck) {
          return this.$message.error('请点击智能校验');
        }
        this.loading = true;
        this.submitDisabled = true;
        this.ncParams.type = 'SMS';
        const iphoneForm = {
          ...this.iphoneForm,
          ...this.ncParams
        };
        const loginRes = await login(iphoneForm, { customError: true });
        this.setUserInfo(loginRes);
        await this.getMenuList();

        this.$message.success("登录成功");
        if (loginRes.superAdmin) {
          setTimeout(() => this.$router.push("/"));
        } else {
          setTimeout(() => this.$router.push("/auth/operator"));
        }

      } catch (e) {
        console.error(e);
        this.submitDisabled = false;
        this.loading = false;
        if (e && e.msg) {
          this.$message({
            message: e.msg,
            type: 'error',
            duration: 2 * 1000,
            onClose: () => window.ic.reset()
          });
        }
      }
    },
    handleClick () { },
    async sendCode () {
      await this.$refs.iphoneForm.validate();
      // 1.时间开始倒数
      // 2.按钮进入禁用状态
      // 3.如果倒计时结束 按钮恢复可用状态 按钮文字变为重新发送, 时间重置
      // 4.倒计时的过程中 按钮文字为 多少s后重新发送
      this.btnText = `${this.time}s`;
      let timer = setInterval(() => {
        this.time--;
        this.btnText = `${this.time}s`;
        this.disabled = true;
        if (this.time === 0) {
          this.disabled = false;
          this.btnText = '重新发送';
          this.time = 60;
          clearInterval(timer);
        }
      }, 1000);
      const params = {
        phone: this.iphoneForm.username,
      };
      // 发送验证码
      await sendCode(params);
    },
    goToShopex () {
      window.open("http://ecboot.it.shopex123.com/oauth/authorize?response_type=code&client_id=43d93dab7ebef303&view=default&redirect_uri=http://ecboot.ex-sandbox.com/api/account/api/v1/auth/otLogin");
    },
  }
};
</script>

<style scoped lang='scss'>
@import '@/style/imports';
.login-page {
  background: #eaeaea;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .login-header {
    padding: 20px 0 20px 30px;
    background: #fff;
    .brand {
      display: inline-block;
      width: 150px;
      height: 60px;
      // overflow: hidden;
      vertical-align: middle;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .login-welcome {
      display: inline-block;
      padding-left: 30px;
      margin-left: 14px;
      border-left: 1px solid #efefef;
      vertical-align: middle;
      color: #666;
    }
  }
  .login-container {
    width: 900px;
    margin: 0 auto;
  }
  .login-body {
    min-height: calc(100% - 73px);
    padding: 120px 0; // 10%
    background-image: url('../../../assets/login_bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .login-container {
      &::after {
        display: table;
        clear: both;
        content: '';
      }
      .login-form,
      .forgot-form {
        float: right;
        width: 400px;
        min-height: 377px;
        padding: 20px 50px 15px;
        background: #fff;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12),
          0 0 6px 0 rgba(0, 0, 0, 0.04);
        .title {
          margin: 0px auto 35px auto;
          text-align: center;
          color: #505458;
          position: relative;
          font-size: 18px;
          .back-login {
            position: absolute;
            right: 0;
            top: 4px;
            font-size: 12px;
            cursor: pointer;
            z-index: 10;
            color: $color-primary;
          }
        }
        .remember {
          float: left;
          font-weight: normal;
        }
        .login-opr {
          margin-bottom: 10px;
        }
        .signup {
          color: #ff5000;
          font-size: 14px;
          position: absolute;
          right: 0;
          line-height: 27px;
          &:hover {
            text-decoration: underline;
          }
        }
        .forgot-pwd {
          float: right;
          color: $color-primary;
          text-decoration: none;
          cursor: pointer;
          display: inline-block;
          &:hover {
            text-decoration: underline;
          }
        }
        .nc-form-item {
          height: 34px;
          margin-bottom: 20px;
        }
        .password {
          position: relative;
          .iconfont {
            position: absolute;
            right: 10px;
            top: 0;
            z-index: 10;
            font-size: 12px;
            color: #666;
            cursor: pointer;
          }
        }
        .submit-btn {
          width: 100%;
        }
      }
      .btnText {
        padding: 0;
        line-height: 38px;
        text-align: center;
        width: 112px;
      }
      .mode {
        width: 100%;
        height: 1px;
        background: #dee0e3;
        margin-top: 30px;
        position: relative;
        &-text {
          position: relative;
          display: inline-block;
          width: 74px;
          text-align: center;
          height: 17px;
          font-size: 12px;
          line-height: 17px;
          color: #8f959e;
          background: #fff;
          left: 112px;
          top: -8px;
        }
      }
      .mode-icon-box {
        cursor: pointer;
        margin: 18px auto;
        width: 34px;
        height: 34px;
        border: 1px solid #dee0e3;
        background: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 50%;
        img {
          width: 14.5px;
          height: 13.5px;
        }
      }
    }
  }
}
</style>
