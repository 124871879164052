
import fetch from './req';

/**
 * 登陆
 * @param data
 * @param config
 * @return {Promise<{
 *   expirationTime: string
 *   nickname: string
 *   token: string
 *   uid: string
 *   username: string
 * }>}
 */
export function login (data, config) {
  return fetch({
    url: '/auth/login',
    method: 'post',
    data,
    ...config
  });
}
export function logout (data = {}) {
  return fetch({
    url: '/auth/logout',
    method: 'post',
    data,
  });
}
export function forgotPassword (data) {
  return fetch({
    url: '/auth/forgotPassword',
    method: 'post',
    data
  });
}
export function setPassword (data) {
  return fetch({
    url: '/auth/resetPassword',
    method: 'post',
    data
  });
}
export function getUserInfo (query) {
  return fetch({
    url: '/auth/getUserInfo',
    method: 'get',
    params: query
  });
}
export function getMenuList (query) {
  return fetch({
    url: '/auth/menu/list',
    method: 'get',
    params: query
  });
}

export function getAccessToken (query) {
  return fetch({
    url: '/openapi/user/getAccessToken',
    method: 'get',
    params: query
  });
}

export function checkLogin (query, config) {
  return fetch({
    url: '/openapi/user/check',
    method: 'get',
    params: query,
    ...config
  });
}

export function getSystemInfo (query) {
  return fetch({
    url: '/openapi/user/system/info',
    method: 'get',
    params: query
  });
}

/**
 * 发送登陆短信
 * @param phone
 */
export function sendCode (data) {
  return fetch({
    url: '/auth/login/sendCode',
    method: 'post',
    data
  });
}

/**
 * 获得租户登陆页信息
 * @param phone
 */
export function identification (query) {
  return fetch({
    url: '/tenant/login/identification',
    method: 'get',
    params: query
  });
}