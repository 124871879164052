import {getSystemInfo} from '@/api/setting';

export default {
  data () {
    return {
      pwdPlaceholder: '请输入密码',
      humanCheck: false,
      pwdMaxLength: 0,
      pwdMiniLength: 0,
    };
  },
  methods: {
    async setPwdRule () {
      const res = await getSystemInfo();
      const ruleStr = [];
      const ruleKey = ['passwordRuleLarge', 'passwordRuleSmall', 'passwordRuleNumber', 'passwordRuleSpecial'];
      const rules = {};
      if(res && res.length !== 0) {
        res.forEach(item => {
          rules[item.key] = item;
          if (ruleKey.includes(item.key) && item.value !== '0') {
            ruleStr.push(item.memo);
          }
        });
        const max = rules.passwordLengthMax.value;
        const mini = rules.passwordLengthMin.value;
        this.pwdMaxLength = max;
        this.pwdMiniLength = mini;
        this.humanCheck = rules.humanCheck.value === '1';
        this.pwdPlaceholder = `${mini}~${max}位字符,${ruleStr.join('、')}`.replace('大写字母、小写字母', '大小写字母');
      }
    }
  }
};
